import { MarketplaceIntegration } from '@/types/marketplace'

export const marketplaceService = { getIcon }

function getIcon(integrationType: MarketplaceIntegration['type']) {
    if (integrationType === 'activation') {
        return { color: '#A5C94B', name: '$analytics' }
    }
    if (integrationType === 'setting') {
        return { color: 'rgb(255 197 73)', name: 'mdi-cog' }
    }
    return { color: '#aeb4bd', name: 'mdi-help' }
}
