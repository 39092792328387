
import { defineComponent, ref, PropType } from 'vue'
import { MarketplaceIntegration, MarketplaceIntegrationFeedback } from '../../../types/marketplace'
import IntegrationMedia from './IntegrationMedia.vue'
import IntegrationFeedbackSlider from './IntegrationFeedbackSlider.vue'
import useRouter from '../../../hooks/useRouter'
import { useProject } from '../../../hooks/useProject'
export default defineComponent({
    props: {
        integration: {
            type: Object as PropType<MarketplaceIntegration>,
            required: true,
        },
        integrationFeedback: {
            type: Array as PropType<MarketplaceIntegrationFeedback[]>,
            required: true,
        },
    },
    components: { IntegrationMedia, IntegrationFeedbackSlider },
    setup(props, { emit }) {
        const { router } = useRouter()
        const { project } = useProject()
        const goToFeedback = () =>
            router.push(
                `/project/${project.value.id}/marketplace/integration/${props.integration.id}?section=feedback`
            )
        return { goToFeedback }
    },
})
