
import { defineComponent, ref, PropType, computed } from 'vue'
import { MarketplaceIntegration } from '../../../types/marketplace'
import Slider from '../../../components/ui/Slider/Slider.vue'
import qs from 'query-string'
export default defineComponent({
    props: {
        integration: {
            type: Object as PropType<MarketplaceIntegration>,
            required: true,
        },
    },
    components: { Slider },
    setup(props, { emit }) {
        const srcIsYoutube = (src: string) => src.startsWith(`https://www.youtube.com`)
        const media = computed(
            () =>
                props.integration.descriptionMedia?.map(el => ({
                    ...el,
                    type: srcIsYoutube(el.src) ? 'youtube' : 'image',
                })) ?? []
        )
        const sliderOptions = computed(() => ({
            loop: false,
            slides: { spacing: 24, perView: media.value.length > 1 ? 2 : 1 },
        }))

        const fullscreenModalOpened = ref(false)
        const fullscreenImageSrc = ref()
        const openFullscreenModal = (item: any) => {
            fullscreenModalOpened.value = true
            fullscreenImageSrc.value = item.src
        }

        const getYoutubeSrc = (url: string) => {
            const parsedUrl = qs.parseUrl(url)
            const youtubeVideoId = parsedUrl.query.v
            return `https://www.youtube.com/embed/${youtubeVideoId}`
        }

        return {
            media,
            sliderOptions,
            getYoutubeSrc,
            fullscreenModalOpened,
            openFullscreenModal,
            fullscreenImageSrc,
        }
    },
})
