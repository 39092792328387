import { render, staticRenderFns } from "./FeedbackModal.vue?vue&type=template&id=92941356&scoped=true"
import script from "./FeedbackModal.vue?vue&type=script&lang=ts"
export * from "./FeedbackModal.vue?vue&type=script&lang=ts"
import style0 from "./FeedbackModal.vue?vue&type=style&index=0&id=92941356&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "92941356",
  null
  
)

export default component.exports