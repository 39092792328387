import { computed, getCurrentInstance, onMounted, ref, watch } from 'vue'
import 'keen-slider/keen-slider.min.css'
import KeenSlider, { KeenSliderOptions } from 'keen-slider'
import useRouter from './useRouter'

interface useTabsParams {
    tabs: {
        tabName: string
        sectionName: string
        component: any
    }[]
    defaultSectionName: string
}

export function useTabs(params: useTabsParams) {
    const { route, router } = useRouter()
    const activeTab = ref(0)
    const tabs = computed(() => params.tabs)
    watch(activeTab, () => {
        const sectionName = tabs.value[activeTab.value || 0].sectionName
        router.push({
            path: route.value.path,
            query: Object.assign({}, route.value.query, { section: sectionName }),
        })
    })
    watch(
        () => route.value.query.section,
        () => {
            const sectionName = route.value.query.section
            const tubNum = tabs.value.findIndex(el => el.sectionName === sectionName)
            activeTab.value = tubNum
        }
    )

    onMounted(() => {
        const section = route.value.query.section
        if (section) {
            activeTab.value = tabs.value.findIndex(el => el.sectionName === section)
        } else {
            activeTab.value = tabs.value.findIndex(el => el.sectionName === params.defaultSectionName)
        }
    })
    return { tabs, activeTab }
}
