//https://superresearch.ru/time-zones
export const timezones = [
    { timezone: 'UTC+0', offset: '+0', cities: [] },
    { timezone: 'UTC+1', offset: '+1', cities: [] },
    { timezone: 'UTC+2', offset: '+2', cities: ['Калининград'] },
    {
        timezone: 'UTC+3',
        offset: '+3',
        cities: [
            'Москва',
            'Санкт-Петербург',
            'Нижний Новгород',
            'Казань',
            'Ростов-на-Дону',
            'Воронеж',
            'Краснодар',
            'Саратов',
            'Ярославль',
            'Махачкала',
            'Рязань',
            'Набережные Челны',
            'Пенза',
            'Липецк',
            'Киров',
            'Чебоксары',
            'Тула',
            'Курск',
            'Ставрополь',
            'Сочи',
            'Тверь',
            'Иваново',
            'Брянск',
            'Белгород',
            'Владимир',
            'Архангельск',
            'Калуга',
            'Смоленск',
            'Череповец',
            'Саранск',
            'Орёл',
            'Вологда',
            'Владикавказ',
            'Грозный',
            'Мурманск',
            'Тамбов',
            'Петрозаводск',
            'Кострома',
            'Новороссийск',
            'Йошкар-Ола',
            'Таганрог',
            'Сыктывкар',
            'Нальчик',
            'Нижнекамск',
            'Шахты',
            'Дзержинск',
            'Старый Оскол',
            'Великий Новгород',
            'Псков',
            'Минеральные Воды',
            'Севастополь',
            'Симферопль',
        ],
    },
    {
        timezone: 'UTC+4',
        offset: '+4',
        cities: ['Самара', 'Волгоград', 'Тольятти', 'Ижевск', 'Ульяновск', 'Астрахань'],
    },
    {
        timezone: 'UTC+5',
        offset: '+5',
        cities: [
            'Екатеринбург',
            'Челябинск',
            'Уфа',
            'Пермь',
            'Тюмень',
            'Оренбург',
            'Магнитогорск',
            'Сургут',
            'Нижний Тагил',
            'Курган',
            'Стерлитамак',
            'Нижневартовск',
            'Орск',
        ],
    },
    { timezone: 'UTC+6', offset: '+6', cities: ['Омск'] },
    {
        timezone: 'UTC+7',
        offset: '+7',
        cities: [
            'Новосибирск',
            'Красноярск',
            'Барнаул',
            'Томск',
            'Кемерово',
            'Новокузнецк',
            'Бийск',
            'Прокопьевск',
            'Норильск',
        ],
    },
    {
        timezone: 'UTC+8',
        offset: '+8',
        cities: ['Иркутск', 'Улан-Удэ', 'Братск', 'Ангарск'],
    },
    {
        timezone: 'UTC+9',
        offset: '+9',
        cities: ['Чита', 'Якутск', 'Благовещенск'],
    },
    {
        timezone: 'UTC+10',
        offset: '+10',
        cities: ['Хабаровск', 'Владивосток'],
    },
    {
        timezone: 'UTC+11',
        offset: '+11',
        cities: ['Магадан'],
    },
]
