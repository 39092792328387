import { computed, getCurrentInstance, onMounted, ref } from 'vue'
import 'keen-slider/keen-slider.min.css'
import KeenSlider, { KeenSliderInstance, KeenSliderOptions } from 'keen-slider'

interface UseSliderParams {
    refName?: string
    sliderOptions?: KeenSliderOptions
}
const getParams = (params: UseSliderParams) => ({
    refName: params.refName ?? 'slider-container',
    sliderOptions: params.sliderOptions ?? {},
})
export function useSlider(_params?: UseSliderParams) {
    const root = getCurrentInstance()!.proxy as any
    const { refName, sliderOptions } = getParams(_params ?? {})
    const slider = ref<KeenSliderInstance>({} as KeenSliderInstance)
    const silderRef = ref()
    onMounted(() => {
        silderRef.value = root.$refs[refName]
        slider.value = new KeenSlider(silderRef.value, sliderOptions)
    })
    return { silderRef, slider }
}
