
import { computed, defineComponent, ref, PropType, onMounted, onUnmounted, watch, unref } from 'vue'
import moment from 'moment'
import { useMarketplaceStore } from '../../../../store/stores/marketplace'
import { useProject } from '../../../../hooks/useProject'
import { IntegrationInstallPayload, MarketplaceIntegration } from '../../../../types/marketplace'
import FlatSelect from '@/components/ui/FlatSelect.vue'
import * as _ from 'lodash'
import SpinnerLoader from '../../../../components/SpinnerLoader.vue'
import BaseInputOutlined from '../../../../components/inputs/BaseInputOutlined.vue'
import DatePicker from '../../../../components/ui/DatePicker.vue'
import { timezones } from '../../../../vars/timezones'
import TimeInput from '../../../../components/inputs/TimeInput.vue'
import CustomActivatorSelect from '../../../../components/inputs/CustomActivatorSelect.vue'
import { checkInputIsIntNumber } from '../../../../utils'
import api from '../../../../api/api'
export default defineComponent({
    components: {
        SpinnerLoader,
        FlatSelect,
        BaseInputOutlined,
        DatePicker,
        TimeInput,
        CustomActivatorSelect,
    },
    props: {
        value: { type: Object as PropType<IntegrationInstallPayload>, required: true },
        integration: { type: Object as PropType<MarketplaceIntegration>, required: true },
    },
    setup(props, { emit }) {
        const automailSettings = ref<any>()
        const timezonesList = timezones.map(el => ({
            id: parseInt(el.offset.replace(/[^0-9]/g, '')),
            name: el.cities.length ? `${el.timezone} (${el.cities.slice(0, 3).join(', ')})` : el.timezone,
        }))
        const form = ref()
        const formValid = ref<boolean>(false)

        const validationRules = {
            isNotEmpty: (v: any) => !!v || 'Обязательно',
            biggerThan15: (v: any) => Number(v) >= 15 || 'Должно быть больше 15',
        }

        const loading = ref(false)
        const marketplaceStore = useMarketplaceStore()
        const { project } = useProject()
        const selectedActivationIds = ref<number[]>([])
        const activationList = computed(() => {
            return _.get(marketplaceStore.activationsList, props.integration.id)
        })
        const hasAutmaticActivations = computed(() => activationList.value?.some(el => el.automatic))

        const flatListItemHeight = computed(() => (hasAutmaticActivations.value ? 42 : 40))
        const flatListHeight = computed(() => (hasAutmaticActivations.value ? 200 : 0))
        const currentDate = computed(() => moment().format('DD.MM.YYYY'))

        onMounted(async () => {
            loading.value = true
            if (!activationList.value) {
                await marketplaceStore.fetchActivationsList(project.value.id, props.integration.id)
            }
            automailSettings.value = await api.marketplace
                .getAutomailSettings({ projectId: project.value.id, integrationId: props.integration.id })
                .then(res => res.data)

            loading.value = false
            selectedActivationIds.value = activationList.value.map(el => el.id)
        })
        const updateValidtion = () => {
            const formValidСonsiderAutomatic = hasAutmaticActivations.value ? formValid.value : true
            const valid = formValidСonsiderAutomatic && Boolean(selectedActivationIds.value.length)
            const text = selectedActivationIds.value.length
                ? 'Заполните все обязательные поля'
                : 'Выберите хотя бы одну активацию'
            emit('update-validation', { valid, text: text })
        }

        watch(selectedActivationIds, () => {
            emit('input', {
                selectedActivationIds: unref(selectedActivationIds),
                automail: automailSettings.value,
            })
            updateValidtion()
        })

        onUnmounted(() => {})
        return {
            activationList,
            selectedActivationIds,
            loading,
            hasAutmaticActivations,
            flatListItemHeight,
            flatListHeight,
            timezonesList,
            checkInputIsIntNumber,
            automailSettings,
            updateValidtion,
            form,
            currentDate,
            formValid,
            validationRules,
        }
    },
})
