
import { v4 as uuidv4 } from 'uuid'
import { computed, defineComponent, ref, shallowRef, watch, PropType } from 'vue'
import * as _ from 'lodash'
import moment from 'moment'
import BaseInputOutlined from '../inputs/BaseInputOutlined.vue'
export default defineComponent({
    components: { BaseInputOutlined },
    props: {
        value: { type: [String, Array] as PropType<string | string[]> },
        label: { type: String, default: null },
        dateFormat: { type: String, default: 'DD.MM.YYYY' },
        range: { type: Boolean, default: false },
        clearable: { type: Boolean, default: true },
        hideDetails: { type: Boolean, default: false },
        hint: { type: String, default: '' },
        persistentHint: { type: Boolean, default: false },
        rules: { type: Array, default: () => [] },
        placeholder: { type: String, default: '' },
    },
    setup(props, { emit }) {
        const DATE_PICKER_DATE_FORMAT = 'YYYY-MM-DD'
        const menuIsOpened = ref(false)

        const sortedDates = computed(() => {
            if (Array.isArray(props.value)) {
                return [
                    ...props.value.sort(
                        (a, b) =>
                            moment(a, DATE_PICKER_DATE_FORMAT).unix() -
                            moment(b, DATE_PICKER_DATE_FORMAT).unix()
                    ),
                ]
            } else {
                return [props.value]
            }
        })
        const appendIcon = computed(() => {
            if (!props.clearable) return null
            return 'mdi-close'
        })
        const dateText = computed(() => {
            const formatedDates = sortedDates.value.map(date =>
                _.isNil(date) ? '' : moment(date, DATE_PICKER_DATE_FORMAT).format(props.dateFormat)
            )
            return formatedDates.join(' - ')
        })
        const dates = computed({
            get: () => props.value,
            set: (v: any) => {
                emit('input', v)
            },
        })

        const closeMenu = () => {
            menuIsOpened.value = false
        }
        const clear = () => {
            if (!props.clearable) return
            emit('input', null)
        }

        return { menuIsOpened, dates, dateText, closeMenu, clear, appendIcon }
    },
})
