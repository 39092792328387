
import { defineComponent, PropType, computed, onMounted, ref } from 'vue'
import { MarketplaceIntegration } from '../../../types/marketplace'
import { useProject } from '../../../hooks/useProject'
import { marketplaceService } from '../marketplaceSerivce'

export default defineComponent({
    props: {
        integration: {
            type: Object as PropType<MarketplaceIntegration>,
            required: true,
        },
    },
    components: {},
    setup(props, {}) {
        const { project } = useProject()
        const icon = computed(() => marketplaceService.getIcon(props.integration.type))
        return { icon }
    },
})
