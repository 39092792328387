
import { defineComponent, ref, PropType, computed } from 'vue'
import { MarketplaceIntegration, MarketplaceIntegrationFeedback } from '../../../types/marketplace'
import colors from 'vuetify/lib/util/colors'
import * as _ from 'lodash'
import moment from 'moment'
export default defineComponent({
    props: {
        integrationFeedbackItem: {
            type: Object as PropType<MarketplaceIntegrationFeedback>,
            required: true,
        },
        maxLines: {
            type: Number,
        },
    },
    setup(props, { emit }) {
        const avatarColors = [
            colors.amber.accent1,
            colors.cyan.accent1,
            colors.green.accent1,
            colors.indigo.accent1,
            colors.yellow.accent1,
            colors.red.accent1,
        ]
        const projectInitials = computed(() =>
            props.integrationFeedbackItem.projectName.slice(0, 2).toUpperCase()
        )
        const formatDate = (date: string) => moment(date).format('LL')
        const getRandomColor = () => _.sample(avatarColors)
        return { getRandomColor, projectInitials, formatDate }
    },
})
