
import { defineComponent, PropType, computed, onMounted, ref } from 'vue'
import { IntegrationInstallPayload, MarketplaceIntegration } from '../../../types/marketplace'
import { useProject } from '../../../hooks/useProject'
import { declOfNum } from '../../../utils'
import { useMarketplaceStore } from '../../../store/stores/marketplace'
import IntegrationInstallModal from './IntegrationInstallModal/IntegrationInstallModal.vue'
export default defineComponent({
    props: {
        integration: {
            type: Object as PropType<MarketplaceIntegration>,
            required: true,
        },
    },
    components: { IntegrationInstallModal },
    setup(props, {}) {
        const { project } = useProject()
        const marketplaceStore = useMarketplaceStore()
        const installIntegrationModalOpened = ref(false)
        const loading = ref(false)
        const price = computed(() =>
            props.integration.price ? `От ${props.integration.price} ₽/мес.` : 'Бесплатно'
        )
        const bottomText = computed(() => {
            const trial = props.integration.trialDays
            if (props.integration.status === 'freezed') return 'Приостановлено!'
            if (trial) return `Бесплатный период — ${trial} ${declOfNum(trial, ['день', 'дня', 'дней'])}`
        })
        const bottomTextClass = computed(() => {
            const trial = props.integration.trialDays
            if (props.integration.status === 'freezed') return 'freezed'
            if (trial) return 'trial'
        })
        const connectButtonText = computed(() =>
            props.integration.status === 'installed' ? 'Отключить' : 'Подключить'
        )
        const installIntegration = async () => {
            if (props.integration.status === 'installed') {
                return connectIntegration()
            }
            if (props.integration.type === 'activation') {
                installIntegrationModalOpened.value = true
                return
            }
            if (props.integration.type === 'setting') {
                await connectIntegration()
                return
            }
        }
        const connectIntegration = async (installPayload?: IntegrationInstallPayload) => {
            loading.value = true
            if (props.integration.status === 'uninstalled') {
                await marketplaceStore.installIntegration({
                    integrationId: props.integration.id,
                    projectId: project.value.id,
                    installPayload,
                })
                loading.value = false
                return
            }
            if (props.integration.status === 'installed') {
                await marketplaceStore.uninstallIntegration({
                    integrationId: props.integration.id,
                    projectId: project.value.id,
                })
                loading.value = false
                return
            }
            loading.value = false
        }
        return {
            price,
            bottomText,
            bottomTextClass,
            connectButtonText,
            loading,
            connectIntegration,
            installIntegration,
            installIntegrationModalOpened,
        }
    },
})
