
import { computed, defineComponent, ref, PropType, unref } from 'vue'
import { useMarketplaceStore } from '../../../../store/stores/marketplace'
import { useProject } from '../../../../hooks/useProject'
import { IntegrationInstallPayload, MarketplaceIntegration } from '../../../../types/marketplace'
import ActivationTypeModalContent from './ActivationTypeModalContent.vue'

interface Validation {
    valid: boolean
    text: string
}

export default defineComponent({
    props: {
        value: { type: Boolean, required: true },
        integration: { type: Object as PropType<MarketplaceIntegration> },
    },
    setup(props, { emit }) {
        const marketplaceStore = useMarketplaceStore()
        const { project } = useProject()
        const installPayload = ref<IntegrationInstallPayload>({})
        const validation = ref<Validation>({ text: '', valid: false })
        const connectButtonDisabled = ref(false)
        const loading = ref(false)
        const activeContentComponent = computed<any>(() => {
            if (!props.integration) {
                return
            }
            if (props.integration.type === 'activation') {
                return ActivationTypeModalContent
            }
        })
        const updateValidation = (v: Validation) => {
            validation.value = v
            connectButtonDisabled.value = !validation.value.valid
        }
        const closeModal = () => {
            isOpened.value = false
        }
        const installIntegration = () => {
            emit('install', unref(installPayload))
            closeModal()
        }
        const isOpened = computed({
            get: () => props.value,
            set: (v: boolean) => emit('input', v),
        })

        return {
            isOpened,
            loading,
            activeContentComponent,
            closeModal,
            connectButtonDisabled,
            installPayload,
            updateValidation,
            installIntegration,
            validation,
        }
    },
})
