
import { computed, defineComponent, nextTick, onMounted, ref, watch } from 'vue'
import Slider from '../../../components/ui/Slider/Slider.vue'
import SpinnerLoader from '../../../components/SpinnerLoader.vue'
import Breadcrumbs from '../../../components/Breadcrumbs.vue'
import { useProject } from '../../../hooks/useProject'
import { useMarketplaceStore } from '../../../store/stores/marketplace'
import useRouter from '../../../hooks/useRouter'
import IntegrationTitle from '../components/IntegrationTitle.vue'
import IntegrationFeedback from '../components/IntegrationFeedback.vue'
import IntegrationInfo from '../components/IntegrationInfo.vue'
import IntegrationConnect from '../components/IntegrationConnect.vue'
import { useTabs } from '../../../hooks/useTabs'

export default defineComponent({
    components: {
        Slider,
        SpinnerLoader,
        Breadcrumbs,
        IntegrationTitle,
        IntegrationConnect,
        IntegrationInfo,
        IntegrationFeedback,
    },
    props: {},
    setup(props, { emit }) {
        const { project } = useProject()
        const { route } = useRouter()
        const marketplaceStore = useMarketplaceStore()
        const allIntgrations = computed(() => marketplaceStore.allIntgrations(project.value.id))

        const integration = computed(() =>
            allIntgrations.value.find(el => el.id === route.value.params.integrationId)
        )
        const integrationFeedback = computed(() => {
            return marketplaceStore.integrationFeedback(route.value.params.integrationId)
        })
        const { activeTab, tabs } = useTabs({
            tabs: [
                {
                    tabName: 'ИНФОРМАЦИЯ',
                    sectionName: 'info',
                    component: IntegrationInfo,
                },
                {
                    tabName: 'ОТЗЫВЫ',
                    sectionName: 'feedback',
                    component: IntegrationFeedback,
                },
            ],
            defaultSectionName: 'info',
        })

        const breadcrumbs = computed(() => [
            {
                text: 'ВАШИ ПРОЕКТЫ',
                to: '/projects',
            },
            {
                text: project.value.name,
                to: `/project/${project.value.id}`,
            },
            {
                text: 'МАРКЕТПЛЕЙС',
                to: `/project/${project.value.id}/marketplace`,
                disabled: false,
            },
            {
                text: integration.value?.name ?? 'ЗАГРУЗКА...',
                to: '',
                disabled: true,
            },
        ])
        const loading = ref(true)

        onMounted(async () => {
            loading.value = true
            const promises = []
            console.log('???')
            if (!allIntgrations.value.length) {
                promises.push(marketplaceStore.fetchIntegrations(project.value.id))
            }

            if (!integrationFeedback.value.length) {
                promises.push(marketplaceStore.fetchFeedback(route.value.params.integrationId))
            }
            await Promise.all(promises)
            loading.value = false
            console.log('??', promises)
        })
        return { loading, breadcrumbs, integration, activeTab, tabs, integrationFeedback }
    },
})
