
import { defineComponent, ref, PropType, computed } from 'vue'
import { MarketplaceIntegration, MarketplaceIntegrationFeedback } from '../../../types/marketplace'
import { declOfNum } from '../../../utils'
import IntegrationFeedbackCard from '../components/IntegrationFeedbackCard.vue'
import NoFeedback from './NoFeedback.vue'
import FeedbackModal from './FeedbackModal.vue'
import { useProject } from '../../../hooks/useProject'
export default defineComponent({
    props: {
        integration: {
            type: Object as PropType<MarketplaceIntegration>,
            required: true,
        },
        integrationFeedback: {
            type: Array as PropType<MarketplaceIntegrationFeedback[]>,
            required: true,
        },
    },
    components: { IntegrationFeedbackCard, NoFeedback, FeedbackModal },
    setup(props, { emit }) {
        const { project } = useProject()
        const feedbackModalOpened = ref(false)
        const feedbackAlreadyExists = computed(() =>
            Boolean(props.integrationFeedback.find(el => el.projectId === project.value.id))
        )
        const integrationInstalled = computed(() => Boolean(props.integration.status === 'installed'))
        const disabledButtonTooltipText = computed(() => {
            if (!integrationInstalled.value) return 'Интеграция не установлена'
            if (feedbackAlreadyExists) return 'Отзыв уже оставлен'
        })
        const installButtonDisabled = computed(
            () => feedbackAlreadyExists.value || !integrationInstalled.value
        )
        const openFeedbackModal = () => {
            feedbackModalOpened.value = true
        }
        const feedbackCount = computed(
            () =>
                `${props.integrationFeedback.length} ${declOfNum(props.integrationFeedback.length, [
                    'отзыв',
                    'отзыва',
                    'отзывов',
                ])}`
        )
        return {
            feedbackCount,
            feedbackModalOpened,
            openFeedbackModal,
            feedbackAlreadyExists,
            integrationInstalled,
            disabledButtonTooltipText,
            installButtonDisabled,
        }
    },
})
