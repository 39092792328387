
import { defineComponent, ref, PropType } from 'vue'
import { MarketplaceIntegration, MarketplaceIntegrationFeedback } from '../../../types/marketplace'
import IntegrationFeedbackCard from '../components/IntegrationFeedbackCard.vue'
import Slider from '../../../components/ui/Slider/Slider.vue'
import NoFeedback from './NoFeedback.vue'
export default defineComponent({
    props: {
        integrationFeedback: {
            type: Array as PropType<MarketplaceIntegrationFeedback[]>,
            required: true,
        },
    },
    components: { IntegrationFeedbackCard, Slider, NoFeedback },
    setup(props, { emit }) {
        const sliderOptions = { slides: { spacing: 24, perView: 2 } }
        return { sliderOptions }
    },
})
