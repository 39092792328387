
import { v4 as uuidv4 } from 'uuid'
import { computed, defineComponent, ref, shallowRef, watch, PropType } from 'vue'
import * as _ from 'lodash'
export default defineComponent({
    components: {},
    props: {
        value: { type: null, default: () => [] },
        items: { type: Array as PropType<{ id: string | number; name: string }[]>, default: () => [] },
        itemValue: { type: String, default: 'id' },
        itemName: { type: String, default: 'name' },
        selectAllText: { type: String, default: 'Выбрать все' },
        vScrollMaxHeight: { type: Number, default: 400 },
        vScrollMinHeight: { type: Number, default: 0 },
        vScrollItemHeight: { type: Number, default: 48 },
    },
    setup(props, { emit }) {
        const vScrollHeight = computed(() =>
            _.clamp(
                props.items.length * props.vScrollItemHeight,
                props.vScrollMinHeight,
                props.vScrollMaxHeight
            )
        )
        const update = (val: any) => {
            emit('input', val)
        }
        const select = (item: any) => {
            if (item.disabled) return
            const val = !props.value.includes(item[props.itemValue])
            if (val) {
                update(props.value.concat(item[props.itemValue]))
            } else {
                update(props.value.filter((el: any) => el !== item[props.itemValue]))
            }
        }
        const selectedAll = computed(() => props.value.length === props.items?.length)
        const selectAllIcon = computed(() => {
            if (selectedAll.value) return 'mdi-checkbox-outline'
            if (props.value.length) return 'mdi-minus-box-outline'
            return 'mdi-checkbox-blank-outline'
        })
        const selectAll = () => {
            if (props.value.length) {
                update([])
            } else {
                update(props.items.filter((el: any) => !el.disabled).map((el: any) => el[props.itemValue]))
            }
        }
        return { vScrollHeight, select, selectAllIcon, selectAll }
    },
})
