
import { computed, defineComponent, ref } from 'vue'
import { useMarketplaceStore } from '../../../store/stores/marketplace'
import { useProject } from '../../../hooks/useProject'

export default defineComponent({
    props: {
        value: { type: Boolean, required: true },
        integrationId: { type: String, required: true },
    },
    setup(props, { emit }) {
        const marketplaceStore = useMarketplaceStore()
        const { project } = useProject()
        const loading = ref(false)
        const rating = ref(0)
        const comment = ref('')
        const sendFeedbackButtonDisabled = computed(() => !rating.value || !comment.value)
        const closeModal = () => {
            isOpened.value = false
        }

        const createFeedback = async () => {
            loading.value = true
            await marketplaceStore.createFeedback({
                comment: comment.value,
                integrationId: props.integrationId,
                projectId: project.value.id,
                rating: rating.value,
            })
            loading.value = false
            closeModal()
        }

        const isOpened = computed({
            get: () => props.value,
            set: (v: boolean) => emit('input', v),
        })

        return { isOpened, rating, comment, sendFeedbackButtonDisabled, closeModal, loading, createFeedback }
    },
})
