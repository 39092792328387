import { defineStore } from 'pinia'

import _ from 'lodash'
import {
    MarketplaceIntegration,
    MarketplaceIntegrationFeedback,
    integrationActivationList,
} from '../../types/marketplace'
import api from '../../api/api'
import store from '..'
import { sleep } from '../../utils'
import { Tag } from '../../types/main'
import { CreateFeedbackParams, InstallIntegrationParams } from '../../api/marketplace'
export const useMarketplaceStore = defineStore('marketplace', {
    state: () => ({
        integrations: {} as { [projectId: string]: MarketplaceIntegration[] },
        feedback: {} as { [integrationId: string]: MarketplaceIntegrationFeedback[] },
        activationsList: {} as { [integrationId: string]: integrationActivationList },
    }),
    actions: {
        async fetchIntegrations(projectId: string) {
            const { data, error } = await api.marketplace.getAll(projectId)
            if (error) {
                store.dispatch('callNotify', 'Произошла ошибка')
            }
            this.integrations = Object.assign({ [projectId]: data }, this.integrations)
        },
        async fetchFeedback(integrationId: string) {
            const { data, error } = await api.marketplace.getFeedback(integrationId)
            if (error) {
                store.dispatch('callNotify', 'Произошла ошибка')
            }
            this.feedback = Object.assign({ [integrationId]: data }, this.feedback)
        },
        async fetchActivationsList(projectId: string, integrationId: string) {
            const { data, error } = await api.marketplace.getIntegrationActivationList({
                projectId,
                integrationId,
            })
            if (error) {
                store.dispatch('callNotify', 'Произошла ошибка')
            }
            this.activationsList = Object.assign({ [integrationId]: data }, this.activationsList)
        },
        async createFeedback(params: CreateFeedbackParams) {
            const { data, error } = await api.marketplace.createFeedback(params)
            if (error) {
                return store.dispatch('callNotify', 'Произошла ошибка')
            }
            const integrationFeedback = [...this.feedback[params.integrationId]]
            integrationFeedback.unshift(data!)
            this.feedback = Object.assign({}, this.feedback, { [params.integrationId]: integrationFeedback })
        },
        async installIntegration(params: InstallIntegrationParams) {
            const { data, error } = await api.marketplace.installIntegration(params)
            if (error) {
                return store.dispatch('callNotify', 'Произошла ошибка')
            }
            const projectIntegrations = [...this.integrations[params.projectId]]
            const integration = projectIntegrations.find(el => el.id === params.integrationId)
            if (integration) {
                integration.status = 'installed'
            }
            this.integrations = Object.assign({ [params.projectId]: projectIntegrations }, this.integrations)
        },
        async uninstallIntegration(params: InstallIntegrationParams) {
            const { data, error } = await api.marketplace.uninstallIntegration(params)
            if (error) {
                return store.dispatch('callNotify', 'Произошла ошибка')
            }
            const projectIntegrations = [...this.integrations[params.projectId]]
            const integration = projectIntegrations.find(el => el.id === params.integrationId)
            if (integration) {
                integration.status = 'uninstalled'
            }
            this.integrations = Object.assign({ [params.projectId]: projectIntegrations }, this.integrations)
        },
    },
    getters: {
        integrationFeedback(state) {
            return (integrationId: string): MarketplaceIntegrationFeedback[] =>
                state.feedback[integrationId] ?? []
        },
        allIntgrations(state) {
            return (projectId: string): MarketplaceIntegration[] => state.integrations[projectId] ?? []
        },
        publishedIntegrations(state) {
            return (projectId: string): MarketplaceIntegration[] =>
                state.integrations[projectId]?.filter(el => el.published) ?? []
        },
        tags(state) {
            return (projectId: string): Tag[] => {
                const publishedIntegrations = this.publishedIntegrations(projectId)
                const installedIntgrations = publishedIntegrations.filter(el => el.status === 'installed')
                const installedTag: Tag = {
                    id: '_installed',
                    name: 'Установлено',
                    color: '#A5C94B',
                    totalCount: installedIntgrations.length,
                }
                const otherTags: Tag[] = _.uniq(publishedIntegrations.flatMap(el => el.tags ?? [])).map(
                    tag => ({
                        id: tag!,
                        name: tag!,
                        totalCount: publishedIntegrations.filter(el => el.tags?.includes(tag!)).length,
                    })
                )
                return [installedTag].concat(otherTags)
            }
        },
    },
})
