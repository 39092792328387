
import { defineComponent, ref, PropType, onMounted, computed, onUnmounted, nextTick } from 'vue'
import { useSlider } from '@/hooks/useSlider'
import { KeenSliderOptions } from 'keen-slider'
export default defineComponent({
    components: {},
    props: {
        options: { type: Object as PropType<KeenSliderOptions> },
        hideArrows: { type: Boolean, default: false },
        value: { type: Number },
    },
    setup(props, { emit }) {
        const { slider } = useSlider({
            sliderOptions: { ...(props.options as object), initial: props.value ?? 0 },
        })
        const arrowStyles = ref({})
        const showArrows = computed(() => {
            if (props.hideArrows) return false
            if (!slider.value.slides) return false
            const perView = (props.options as any)?.slides?.perView ?? 1
            if (slider.value.slides.length <= perView) return false
            return true
        })
        onMounted(async () => {
            slider.value.on('slideChanged', v => {
                emit('input', v.track.details.rel)
            })

            setTimeout(() => {
                const height = slider!.value.container.clientHeight
                arrowStyles.value = { bottom: `${height / 2 - 20}px` }
            }, 0)
        })
        onUnmounted(() => {
            slider.value.destroy()
        })
        return { slider, arrowStyles, showArrows }
    },
})
